import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';
import { ValidateRequired } from '@shared/validators/required.validator';
import { ValidateTime } from '@shared/validators/time.validator';
import moment from 'moment';

interface Student {
  id: number;
  school_id: number;
  course_id: number;
  exams_generated_id: number;
}
interface Item {
  name: string;
  cpf_number: string;
  school_id: number;
  course_id: number;
}

interface Data {
  id: number;
  itens: Item[];
  action: number;
  info: any;
  ids: any;
  student: Student;
  lote: any
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  public title = 'AGENDAMENTO';
  public btn = 'Agendar';
  public places = [];
  public avaliators = [];
  public canceled = [];
  public cancelType = [];
  public appointmentForm: UntypedFormGroup;
  public cancelForm: UntypedFormGroup;
  _dateValue;
  public mask = {
    time: [/\d/, /\d/, ':', /\d/, /\d/]
  };

  application: any;
  minDate: any;
  maxDate: any;

  constructor(
    public dialogRef: MatDialogRef<AppointmentComponent>,
    private crud: CrudService,
    private toastr: ToastrService,
    private adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    this.adapter.setLocale('pt-BR');
    this.appointmentForm = new UntypedFormGroup({
      'ids': new UntypedFormControl([]),
      'class_student_id': new UntypedFormControl(this.data.student.id),
      'place_id': new UntypedFormControl(null, [ValidateRequired]),
      'evaluator_user_id': new UntypedFormControl(null, [ValidateRequired]),
      'appointment_timestamp': new UntypedFormControl(null, [ValidateRequired]),
      'time': new UntypedFormControl(null, [ValidateTime, ValidateRequired]),
      'reason': new UntypedFormControl(null, [ValidateRequired]),
      'appointment_status_id': new UntypedFormControl(this.data.action)
    });

    this.cancelForm = new UntypedFormGroup({
      'reason': new UntypedFormControl(null, [ValidateRequired]),
      'appointment_cancel_type_id': new UntypedFormControl(null, [ValidateRequired]),
      'appointment_status_id': new UntypedFormControl(this.data.action)
    });

    const school = this.data.student.school_id || this.data.itens[0].school_id;
    const course = this.data.student.course_id || this.data.itens[0].course_id;

    this.crud.get('public/places?where=school_id,' + school + '&noRelationship=true&show=id,description&order=description,asc')
      .then((res: any) => {
        this.places = res.data || res;
      });

    this.crud.get('public/avaliators?school_id=' + school + '&course_id=' + course + '&order=name,asc')
      .then((res: any) => {
        this.avaliators = res.data || res;
      });

    if (this.data.action == 2) {

      this.crud.get('appointment/canceled?id=' + this.data.student.id)
        .then((res: any) => {
          this.canceled = res.data || res;
        });

    }

    if (this.data.action == 3) {

      this.crud.get('appointment-cancel-type?show=id,description')
        .then((res: any) => {
          this.cancelType = res.data || res;
        });

    }
  }

  ngOnInit() {
    this.application = JSON.parse(sessionStorage.getItem('application'));
    this.minDate = new Date(this.application.initialEvaluationDate);
    this.maxDate = new Date(this.application.endEvaluationDate);

    if (this.data.action == 1) {
      this.appointmentForm.get('reason').setValidators([]);
      this.appointmentForm.get('reason').updateValueAndValidity();
    }

    if (this.data.action == 2) {
      this.title = 'REAGENDAMENTO';
      this.btn = 'Reagendar';
    }

    if (this.data.action == 3) {
      this.title = 'CANCELAR';
      this.btn = 'Cancelar avaliação';
    }

  }

  valid() {
    if (this.data.action == 3) { return this.cancelForm.invalid; }

    return this.appointmentForm.invalid;
  }

  close() {
    this.dialogRef.close();
  }

  appointment() {
    if (this.appointmentForm.invalid) return;
    if (this.data.lote) { return this.loteAppointment(); }

    const newDate = moment(new Date(this.appointmentForm.value.appointment_timestamp)).format('YYYY-MM-DD') + ' ' + this.appointmentForm.value.time + ':00';
    this.appointmentForm.controls['appointment_timestamp'].setValue(newDate);

    this.crud.post('appointment', this.appointmentForm.value)
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
      }).catch((err: any) => {
        this.toastr.warning('Ação não realizada.', 'Atenção!');
      });
  }

  cancelAppointment() {
    const newDate = moment(new Date(this.appointmentForm.value.appointment_timestamp)).format('YYYY-MM-DD')
      + ' ' + this.appointmentForm.value.time + ':00';
    this.appointmentForm.controls['appointment_timestamp'].setValue(newDate);

    const param = this.data.action === 3 ? this.cancelForm.value : this.appointmentForm.value;

    if (this.data.action === 2) { param['appointment_cancel_type_id'] = 4; }//reagendamento

    const obj = {
      exams_generated_id: this.data.student.exams_generated_id,
      class_student_id: this.data.student.id
    };

    this.crud.patch('appointment/' + this.data.id, { ...param, ...obj })
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
      }).catch((err: any) => {
        this.toastr.warning('Ação não realizada.', 'Atenção!');
      });
  }

  loteAppointment() {
    const newDate = moment(new Date(this.appointmentForm.value.appointment_timestamp)).format('YYYY-MM-DD') + ' ' + this.appointmentForm.value.time + ':00';
    this.appointmentForm.controls['appointment_timestamp'].setValue(newDate);

    this.appointmentForm.controls['ids'].setValue(this.data.ids);
    this.crud.post('appointment/lote', this.appointmentForm.value)
      .then((res: any) => {
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
      }).catch((err: any) => {
        this.toastr.warning('Ação não realizada.', 'Atenção!');
      });
  }
}
