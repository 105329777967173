import { DatePipe, registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeBr from '@angular/common/locales/br';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localeBr, 'pt-BR');

/**
 * Components
 */
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

/**
 * Modules
 */
import { SharedModule } from '@shared/shared.module';

/**
 * Routing
 */
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationComponent } from './components/application/application.component';
import { RegisterComponent } from './components/register/register.component';
import { AssociationUserSocialComponent } from './modules/main/components/association-user-social/association-user-social.component';

import Quill from 'quill';
import QuillResizeImage from 'quill-resize-image';
import * as QuillTableUI from 'quill-table-ui';

Quill.register(
  {
    'modules/tableUI': QuillTableUI.default,
    'modules/resize': QuillResizeImage,
  },
  true
);
// Quill.register("modules/resize", QuillResizeImage);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    RegisterComponent,
    AssociationUserSocialComponent,
    ApplicationComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxCaptchaModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RegisterComponent,
    AssociationUserSocialComponent,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
