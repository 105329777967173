<app-modal-header
  [hasClose]="true"
  [title]="'Recuperar Senha'"
  (handleClose)="close()"
></app-modal-header>

<mat-dialog-content class="content-dialog">
  <form class="not-apply-padding" [formGroup]="forgotPasswordForm">
    <div id="text" class="primary-text">
      Para realizar a alteração de senha e voltar a ter acesso, preencha os
      campos abaixo:
    </div>

    <mat-form-field>
      <input
        type="text"
        mask="000.000.000-00"
        [dropSpecialCharacters]="false"
        formControlName="cpf"
        matInput
        placeholder="CPF"
      />

      <mat-error
        *ngIf="
          forgotPasswordForm.controls['cpf'].hasError('required') &&
          forgotPasswordForm.controls['cpf'].touched
        "
      >
        Campo obrigatório
      </mat-error>

      <mat-error
        *ngIf="
          !forgotPasswordForm.controls['cpf'].hasError('required') &&
          !forgotPasswordForm.controls['cpf'].valid &&
          forgotPasswordForm.controls['cpf'].touched
        "
      >
        Cpf inválido
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="date"
        formControlName="birthday"
        matInput
        placeholder="Data de Nascimento"
      />

      <mat-error
        *ngIf="
          forgotPasswordForm.controls['birthday'].hasError(
            'required'
          ) && forgotPasswordForm.controls['birthday'].touched
        "
      >
        Campo obrigatório
      </mat-error>
    </mat-form-field>

    <div *ngIf="!isContinuosIntegration()">
      <!-- <re-captcha
        class="captcha"
        language="pt-BR"
        siteKey="6Le7EXMUAAAAAEvNpc6qtRMbccxWB2rTGIvPytoz"
        (resolved)="handleCorrectCaptcha($event)"
      ></re-captcha> -->
      <ngx-recaptcha2 #captchaElem
        class="captcha"
        siteKey="6Le7EXMUAAAAAEvNpc6qtRMbccxWB2rTGIvPytoz"
        (success)="handleCorrectCaptcha($event)"
        [useGlobalDomain]="false">
      </ngx-recaptcha2>
    </div>

    <div style="margin: 10px 0px">
      <p style="color: red">
        Atenção: Caso não receba o e-mail de recuperação de senha nos próximos
        10 minutos, verifique o SPAM ou Lixo Eletrônico.
      </p>
    </div>
  </form>
</mat-dialog-content>

<div class="modal-footer">
  <div *ngIf="isLoading">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
  @if (!generatedPassword && !errorMsg && !isLoading) {
    <button
      type="button"
      mat-raised-button
      color="accent"
      [disabled]="!forgotPasswordForm.valid"
      (click)="forgotPassword()"
    >
      Recuperar Senha
    </button>
  }

  @if (errorMsg) {
    <div class="flex">
      <p class="new-password-failed">{{ errorMsg }}<i class="password"></i></p>
      @if (feedback?.finded === false) {
        <button
          id="modalApplyBtn"
          type="button"
          mat-raised-button
          color="accent"
          (click)="selfRegister()"
        >
          AUTOCADASTRO
        </button>
      }
    </div>
  }
</div>
