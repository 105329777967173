
<h4 mat-dialog-title ngClass="modal-toolbar-title primary">
  POSICIONAR TEXTO
  <span (click)="close()">
    <mat-icon class="icon-pattern">close</mat-icon>
  </span>
</h4>

<mat-dialog-content class="content">
  <div id="contentToConvert" class="cover-sheet-model example-boundary">
    <img [src]="coverSheetArt.src">
    <form class="" [formGroup]="coverSheetForm" #formDirective="ngForm">
      <div [ngStyle]="contentStyle" class="content-box" cdkDrag (cdkDragEnded)="dragEnded($event)" cdkDragBoundary=".example-boundary" *ngIf="coverSheetData"
        [innerHtml]="coverSheetData.description"></div>
    </form>
  </div>
</mat-dialog-content>

