import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';
import saveAs from 'file-saver';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray, FormArray, FormGroup, FormControl } from '@angular/forms';
import { AppointmentComponent } from '@main/components/appointment/appointment.component';
import { ModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';
import { ExamStatus } from '@shared/interfaces/ExamStatus';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  @ViewChild('uploadFile', { static: false }) uploadFile;
  @ViewChild('inconformidades', { static: false }) matExpansionInconformidades: MatExpansionPanel;
  @ViewChild('topInconformidades', { static: false }) topInconformidades: MatInput;
  public result: any = {};
  public listForm: FormGroup;
  public typeActivity = [];
  public nonCompliance = [];
  public nonComplianceOpen = [];
  public loading = true;
  public loadingAttachment = false;
  public loadingCompressing = false;
  public attachments: any;
  public attachmentDescription: any = '';
  public titleCard = 'HOMOLOGAÇÃO A NÍVEL REGIONAL';
  public title = 'TABULAÇÃO DE RESULTADOS';
  public hasFile = false;
  public offerCompression = false;
  public needToReadNonCompliance = true;
  public fileFormats;
  public maxFileSize;
  public warningText = 'Preencha os campos obrigatórios indicados com "*".';
  public pendingInputs: number = 0;

  private storageRoute = 'storage/user-documents/';

  constructor(
    public dialogRef: MatDialogRef<ResultsComponent>,
    public dialog: MatDialog,
    private crud: CrudService,
    private toastr: ToastrService,
    private _fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.listForm = new FormGroup({
      'id': new FormControl(this.data.exams_generated_id),
      'confirmation': new FormControl({ value: null, disabled: true }, Validators.required),
      'is_open': new FormControl(0),
      'user_id': new FormControl(parseInt(sessionStorage.getItem('user_id'))),
      'description': new FormControl({ value: null, disabled: true }),
      'evidences': this._fb.array([])
    });

    this.crud.get('exam-generated/non-compliance?exam_generated_id=' + this.data.exams_generated_id)
      .then((res: any) => {

        this.nonCompliance = res;
        this.nonComplianceOpen = this.nonCompliance.filter(el => el.is_open == 1);
        this.needToReadNonCompliance = (this.nonComplianceOpen && this.nonComplianceOpen.length > 0);
      }).catch((err: any) => { this.needToReadNonCompliance = false; });

    if (this.data.action == 4) { this.title = 'Confirmação Regional'; }
    if (this.data.action == 5) { this.titleCard = 'HOMOLOGAÇÃO A NÍVEL NACIONAL'; }
    if (this.data.action == 5) { this.title = 'Confirmação Nacional'; }
  }

  ngOnInit() {
    this.crud.get('list?exam_generated_id=' + this.data.exams_generated_id +
      '&matrix_id=' + this.data.matrix_id + '&list_verification_id=' + this.data.list_verification_id).then((res: any) => {

        this.typeActivity = res.activity_evidence;

        this.loadObj(res.profile_evidences);

      });

    this.crud
      .get('exam-generated/setup').then((res: any) => {
        res.forEach(element => {
          if (element.key === 'max_file_size') {
            this.maxFileSize = element.value;
          } else {
            this.fileFormats = element.value;
          }
        });
      });

    this.listAttachments();

    if (this.data.action == 3) {
      this.listForm.controls['confirmation'].setValidators(null);
      this.listForm.controls['confirmation'].updateValueAndValidity();
    }

    const exam_status_id: ExamStatus = this.data.exam_status_id;
    if (exam_status_id == ExamStatus.CONFIRMADO_DR || exam_status_id == ExamStatus.REALIZADO || exam_status_id == ExamStatus.RECUSADO_DN_PARA_DR) {
      this.listForm.get('confirmation')?.enable();
    }

  }

  public canRegionalConfirm(): boolean {
    const confirmationControl = this.listForm.controls['confirmation'];
    const descriptionControl = this.listForm.controls['description'];
    const dataAction = this.data.action;
    if (this.needToReadNonCompliance === true) return false;
    if (this.attachments?.length == 0 && dataAction == 4 && confirmationControl.value != 0) return false;
    if (confirmationControl.invalid === true) return false;
    if ((descriptionControl.value === null || String(descriptionControl.value) == '') && confirmationControl.value != 1) return false;
    if (confirmationControl.value === 1) {
      descriptionControl.reset();
    }
    return true;
  }

  initForm(param) {
    const formArray = <UntypedFormArray>this.listForm.get('evidences');
    param.forEach(element => {
      formArray.push(
        this._fb.group({
          'id': new UntypedFormControl(element.list_verification_generated.id),
          'correct': new UntypedFormControl(element.correct, Validators.required),
          'reason': new UntypedFormControl(element.reason)
        })
      );
    });
  }

  getValidity(index: number): boolean {
    return (<FormArray>this.listForm.get('evidences')).controls[index].valid;
  }

  revalidateEvidence(evidence, index) {
    const evidenceFormGroup = (<FormArray>this.listForm.get('evidences')).controls[index] as FormGroup;
    if (evidence.correct == 1) {
      evidenceFormGroup.get('reason').disable();
    } else {
      evidenceFormGroup.get('reason').enable();
    }
    this.countPendingInputs();
  }

  private countPendingInputs() {
    let pendingInputs = 0;
    this.listForm.value.evidences.forEach(evidence => {
      if (evidence.correct === null || (evidence.correct == 0 && (!evidence.reason || evidence.reason.length < 3))) {
        pendingInputs++;
      }
    });
    this.pendingInputs = pendingInputs;
  }

  loadObj(param) {
    const evidences = [];

    param.map((u) => {

      u.elements.map((element, ind) => {

        element.performance_standard.map((performance, i) => {

          performance.evidences.map((ev, index) => {

            const evidence_alter: any = {};
            for (const key in ev) {
              evidence_alter[key] = ev[key];
            }

            if (i == 0 && index == 0) {

              evidence_alter['competency_unit_description'] = u.description;
              evidence_alter['competency_unit_code'] = u.code;
              evidence_alter['competency_element_description'] = element.description;
              evidence_alter['competency_element_code'] = u.code + '.' + element.code;
              evidence_alter['performance_standards_description'] = performance.description;
              evidence_alter['performance_standards_code'] = u.code + '.' + element.code + '.' + performance.code;
              evidence_alter['performance_standards_span'] = performance.evidences.length || 1;

            } else {
              if (index == 0) {
                evidence_alter['performance_standard'] = performance.description;
                evidence_alter['performance_standards_code'] = u.code + '.' + element.code + '.' + performance.code;
                evidence_alter['performance_standards_span'] = performance.evidences.length || 1;
              }
            }

            evidence_alter['correct'] = ev.list_verification_generated.correct;
            evidence_alter['reason'] = ev.list_verification_generated.reason;
            evidences.push(evidence_alter);

          });

        });

      });

    });

    this.result.evidences = evidences;
    this.initForm(this.result.evidences);
    this.loadActivity(this.result.evidences);

    this.loading = false;
  }

  loadActivity(param) {
    this.result.activity = [];
    this.typeActivity.forEach(element => {
      const obj: any = {};
      obj['description'] = element.description;
      obj['evidences'] = param.filter(el => {
        return el.activity_evidence_id == element.id;
      });
      this.result.activity.push(obj);
    });
  }

  close() {
    this.dialogRef.close();
  }

  change(event) {

    if (event.value == 2) {
      this.listForm.controls['description'].reset();
      this.listForm.controls['description'].disable();
    } else {
      this.listForm.controls['description'].enable();
    }

  }

  private save(param) {
    this.loading = true;
    this.crud
      .put('exam-generated/' + this.data.exams_generated_id, param)
      .then((res: any) => {
        this.listForm.get('evidences')?.reset();
        this.dialogRef.close(true);
        this.toastr.success(res.message, 'Sucesso!');
        this.loading = false;
      }).catch((res: any) => {
        console.log(res);
        this.loading = false;
      });
  }

  private validateBeforeConfirm(): boolean {
    this.countPendingInputs();
    let valid: boolean = true;
    let message: string = '';
    const confirmation = this.listForm.get('confirmation')?.value;
    if (this.pendingInputs > 0) {
      message += `- ${this.pendingInputs} campo(s) pendente(s)`;
      valid = false;
    }
    if (this.needToReadNonCompliance) {
      message += '<br> - Visualizar a(s) inconformidade(s)';
      valid = false;
    }
    if (this.attachments?.length == 0 && confirmation != 0) {
      message += '<br> - Adicionar o(s) anexo(s)';
      valid = false;
    }
    if (valid === false) {
      this.toastr.warning(message, 'Pendências encontradas:', { enableHtml: true });
    }
    return valid;
  }

  resultTabulate(): void {
    if (this.validateBeforeConfirm() === false) return;
    const listFormComplement: any = {};
    this.listForm.get('confirmation')?.enable();
    this.listForm.get('confirmation').setValue(1);

    listFormComplement.exam_status_id = ExamStatus.REALIZADO;
    listFormComplement.regional_confirmation = 0;
    listFormComplement.result_confirmation = 1;

    const listFormToSave = Object.assign({}, listFormComplement, this.listForm.value);

    this.save(listFormToSave);
  }

  resultRegionalConfirm(skipToDecision: boolean): void {
    if (this.validateBeforeConfirm() === false) return;

    this.listForm.get('confirmation')?.enable();

    const listFormComplement: any = {};

    if (skipToDecision) {
      listFormComplement.result_confirmation = 1;
      this.listForm.get('confirmation').setValue(1);
    }

    listFormComplement.exam_status_id = ExamStatus.CONFIRMADO_DR;
    listFormComplement.regional_confirmation = this.listForm.get('confirmation')?.value;

    if (!this.listForm.get('confirmation')?.value) {
      listFormComplement.exam_status_id = ExamStatus.RECUSADO_DR_PARA_AVALIADOR;
      listFormComplement.result_confirmation = 0;
    }

    const listFormToSave = Object.assign({}, listFormComplement, this.listForm.value);

    this.save(listFormToSave);
  }

  nationalConfirm(): void {
    if (this.listForm.invalid) {
      this.toastr.warning(this.warningText, 'Atenção!');
      return;
    }

    const listFormComplement: any = {};
    listFormComplement.exam_status_id = ExamStatus.CONFIRMADO_DN;
    listFormComplement.national_confirmation = 0;

    switch (this.listForm.get('confirmation').value) {
      case 1:
        listFormComplement.exam_status_id = ExamStatus.RECUSADO_DN_PARA_DR;
        listFormComplement.regional_confirmation = 0;
        this.listForm.get('confirmation').setValue(0);
        break;
      case 2:
        listFormComplement.national_confirmation = 1;
        break;
      default:
        listFormComplement.exam_status_id = ExamStatus.RECUSADO_DN_PARA_AVALIADOR;
        listFormComplement.result_confirmation = 0;
        listFormComplement.regional_confirmation = 0;
        break;
    }

    const listFormToSave = Object.assign({}, listFormComplement, this.listForm.value);

    this.save(listFormToSave);
  }

  onUploadMedia(event) {
    const file = event.target ? event.target.files[0] : event[0];
    if (
      this.fileFormats.split(', ').some(x => file.name.indexOf(x) >= 0) && file.size <= this.maxFileSize
    ) {
      this.offerCompression = false;
      this.hasFile = true;
    } else if (this.fileFormats.split(', ').some(x => file.name.indexOf(x) >= 0) && file.size > this.maxFileSize) {
      this.offerCompression = true;
      this.hasFile = false;
      // this.toastr.error('Tamanho invalido!', 'Erro!');
    } else {
      this.offerCompression = false;
      this.hasFile = false;
      this.toastr.error('Formato invalido!', 'Erro!');
    }
  }

  listAttachments() {
    this.crud.get('exam-generated/attachments?exam_generated_id=' + this.data.exams_generated_id)
      .then((res: any) => {
        this.attachments = res;
      });
  }

  removeFile(item) {
    this.crud.delete('exam-generated/attachments-remove/' + item.id + '?exam_generated_id=' + this.data.exams_generated_id)
      .then((res: any) => {
        this.toastr.success('Arquivo removido.', 'Sucesso!');
        this.attachments = res;
      }).catch(err => this.toastr.error('Erro ao realizar operação.', 'Erro!'));
  }

  download(item) {
    this.crud.downloadFileFromUrl(this.storageRoute + item.hashname, item.filename).then(response => {
      saveAs(response, item.filename);
    }).catch(err => {
      this.toastr.error('Erro ao baixar anexo.', 'Erro!');
    });
  }

  async saveAttachment(compressedFile = null, filename = null) {
    this.loadingAttachment = true;
    let base64File;
    let file;

    if (compressedFile && filename) {
      base64File = compressedFile;
      file = { name: filename };
    } else {
      file = this.uploadFile.nativeElement.files[0];

      await this.getBase64(file).then(
        data => {
          base64File = data;
        }
      );
    }

    const fileForm = new FormGroup({
      'file': new FormControl(base64File),
      'filename': new FormControl(file.name),
      'description': new FormControl(String(this.attachmentDescription)),
      'exam_generated_id': new FormControl(this.data.exams_generated_id)
    });

    this.crud.post('exam-generated/upload', fileForm.value).then((res: any) => {
      this.listAttachments();
      this.toastr.success('Arquivo adicionado.', 'Sucesso!');
      this.attachmentDescription = '';
      this.hasFile = false;
    }).catch((err: any) => {
      this.toastr.error('Erro ao realizar operação.', 'Erro!');
    })
      .then(value => { this.loadingAttachment = false; this.attachmentDescription = ''; });
  }

  async compressAttachment() {
    this.loadingAttachment = true;
    this.loadingCompressing = true;
    let base64File;
    const file = this.uploadFile.nativeElement.files[0];

    await this.getBase64(file).then(
      data => {
        base64File = data;
      }
    );

    const fileForm = new UntypedFormGroup({
      'file': new UntypedFormControl(base64File),
      'filename': new UntypedFormControl(file.name),
      'description': new UntypedFormControl(this.attachmentDescription),
      'exam_generated_id': new UntypedFormControl(this.data.exams_generated_id)
    });

    this.crud.post('compress', fileForm.value).then((res: any) => {
      const compressedFile = this.b64toBlob(res.base64);

      saveAs(compressedFile, `compressed_${res.filename}`);

      this.onConfirmCompressedFile(res);
      this.toastr.success('Arquivo comprimido.', 'Sucesso!');
    }).catch((err: any) => {
      this.toastr.error('O arquivo comprimido não é válido.', 'Erro!');
    })
      .then(value => {
        this.loadingCompressing = false;
        this.loadingAttachment = false;
        this.hasFile = false;
        this.offerCompression = false;
      });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  b64toBlob(file) {
    const type = file.split(',')[0].slice(5, -7);
    const byteString = atob(file.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: type });
  }

  focusNonCompliance() {
    this.listForm.markAsDirty();
    this.matExpansionInconformidades.toggle();

    let top = document.getElementById('tableInconformidades');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  onConfirmCompressedFile(file) {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      height: '250px',
      width: 'auto'
    });

    dialogRef.componentInstance.dialogTitle = 'Confirmação';
    dialogRef.componentInstance.dialogMessage = 'Por favor, verifique se o arquivo comprimido está legível e confirme o envio.';
    dialogRef.componentInstance.confirmText = 'Enviar arquivo comprimido';
    dialogRef.componentInstance.cancelText = 'Descartar';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveAttachment(file.base64, file.filename);
      }
    });
  }

  markAsRead() {
    setTimeout(() => {
      this.needToReadNonCompliance = false;
    }, 3000);
  }

  cancel() {
    const dialogRef = this.dialog.open(AppointmentComponent, {
      width: '750px',
      height: 'auto',
      data: {
        student: this.data,
        action: 3,
        id: this.data.appointment_id,
        lote: false,
        info: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
      }
    });

  }

}
