import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import { ReplaySubject } from 'rxjs';
function win() {
  return window;
}
function YouTubeRef() {
  return win()["YT"];
}
function YouTubePlayerRef() {
  return YouTubeRef().Player;
}
const defaultSizes = {
  height: 270,
  width: 367
};
class YoutubePlayerService {
  static {
    this.ytApiLoaded = false;
  }
  constructor(zone) {
    this.zone = zone;
    this.api = new ReplaySubject(1);
    this.createApi();
  }
  loadPlayerApi(options) {
    const doc = win().document;
    if (!YoutubePlayerService["ytApiLoaded"]) {
      YoutubePlayerService.ytApiLoaded = true;
      const playerApiScript = doc.createElement("script");
      playerApiScript.type = "text/javascript";
      playerApiScript.src = `${options.protocol}://www.youtube.com/iframe_api`;
      doc.body.appendChild(playerApiScript);
    }
  }
  setupPlayer(elementId, outputs, sizes, videoId = "", playerVars) {
    const createPlayer = () => {
      if (YouTubePlayerRef) {
        this.createPlayer(elementId, outputs, sizes, videoId, playerVars);
      }
    };
    this.api.subscribe(createPlayer);
  }
  play(player) {
    player.playVideo();
  }
  pause(player) {
    player.pauseVideo();
  }
  playVideo(media, player) {
    const id = media.id.videoId ? media.id.videoId : media.id;
    player.loadVideoById(id);
    this.play(player);
  }
  isPlaying(player) {
    // because YT is not loaded yet 1 is used - YT.PlayerState.PLAYING
    const isPlayerReady = player && player.getPlayerState;
    const playerState = isPlayerReady ? player.getPlayerState() : {};
    const isPlayerPlaying = isPlayerReady ? playerState !== YouTubeRef().PlayerState.ENDED && playerState !== YouTubeRef().PlayerState.PAUSED : false;
    return isPlayerPlaying;
  }
  createPlayer(elementId, outputs, sizes, videoId = "", playerVars = {}) {
    const playerSize = {
      height: sizes.height || defaultSizes.height,
      width: sizes.width || defaultSizes.width
    };
    const ytPlayer = YouTubePlayerRef();
    return new ytPlayer(elementId, {
      ...playerSize,
      events: {
        onReady: ev => {
          this.zone.run(() => outputs.ready && outputs.ready.next(ev.target));
        },
        onStateChange: ev => {
          this.zone.run(() => outputs.change && outputs.change.next(ev));
        }
      },
      playerVars,
      videoId
    });
  }
  toggleFullScreen(player, isFullScreen) {
    let {
      height,
      width
    } = defaultSizes;
    if (!isFullScreen) {
      height = window.innerHeight;
      width = window.innerWidth;
    }
    player.setSize(width, height);
  }
  // adpoted from uid
  generateUniqueId() {
    const len = 7;
    return Math.random().toString(35).substr(2, len);
  }
  createApi() {
    const onYouTubeIframeAPIReady = () => {
      if (win()) {
        win()["onYouTubeIframeAPIReadyCalled"] = true;
        this.api.next();
      }
    };
    win()["onYouTubeIframeAPIReady"] = onYouTubeIframeAPIReady;
    /**
     * If onYouTubeIframeAPIReady is called in other place, then just trigger next
     * This is to prevent player not initializing issue when another player got initialized in other place
     */
    if (win()["onYouTubeIframeAPIReadyCalled"]) {
      this.api.next();
    }
  }
  static {
    this.ɵfac = function YoutubePlayerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || YoutubePlayerService)(i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: YoutubePlayerService,
      factory: YoutubePlayerService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YoutubePlayerService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: i0.NgZone
  }], null);
})();
class YoutubePlayerComponent {
  constructor(playerService, elementRef, renderer) {
    this.playerService = playerService;
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.videoId = '';
    this.height = defaultSizes.height;
    this.width = defaultSizes.width;
    /**
     * @description sets the protocol by the navigator object
     * if there is no window, it sets a default http protocol
     * unless the protocol is set from outside
     */
    this.protocol = this.getProtocol();
    this.playerVars = {};
    // player created and initialized - sends instance of the player
    this.ready = new EventEmitter();
    // state change: send the YT event with its state
    this.change = new EventEmitter();
  }
  ngAfterContentInit() {
    const htmlId = this.playerService.generateUniqueId();
    const playerSize = {
      height: this.height,
      width: this.width
    };
    const container = this.renderer.selectRootElement('#yt-player-ngx-component');
    this.renderer.setAttribute(container, 'id', htmlId);
    this.playerService.loadPlayerApi({
      protocol: this.protocol
    });
    this.playerService.setupPlayer(htmlId, {
      change: this.change,
      ready: this.ready
    }, playerSize, this.videoId, this.playerVars);
  }
  getProtocol() {
    const hasWindow = window && window.location;
    const protocol = hasWindow ? window.location.protocol.replace(':', '') : 'http';
    return protocol;
  }
  static {
    this.ɵfac = function YoutubePlayerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || YoutubePlayerComponent)(i0.ɵɵdirectiveInject(YoutubePlayerService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: YoutubePlayerComponent,
      selectors: [["youtube-player"]],
      inputs: {
        videoId: "videoId",
        height: "height",
        width: "width",
        protocol: "protocol",
        playerVars: "playerVars"
      },
      outputs: {
        ready: "ready",
        change: "change"
      },
      decls: 1,
      vars: 0,
      consts: [["id", "yt-player-ngx-component"]],
      template: function YoutubePlayerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YoutubePlayerComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'youtube-player',
      template: `
    <div id="yt-player-ngx-component"></div>
  `
    }]
  }], () => [{
    type: YoutubePlayerService
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    videoId: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    protocol: [{
      type: Input
    }],
    playerVars: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class NgxYoutubePlayerModule {
  static forRoot() {
    return {
      ngModule: NgxYoutubePlayerModule,
      providers: [YoutubePlayerService]
    };
  }
  static {
    this.ɵfac = function NgxYoutubePlayerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxYoutubePlayerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxYoutubePlayerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [YoutubePlayerService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxYoutubePlayerModule, [{
    type: NgModule,
    args: [{
      declarations: [YoutubePlayerComponent],
      imports: [],
      providers: [YoutubePlayerService],
      exports: [YoutubePlayerComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-youtube-player
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxYoutubePlayerModule, YouTubePlayerRef, YouTubeRef, YoutubePlayerComponent, YoutubePlayerService, defaultSizes, win };
